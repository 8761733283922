import { useEffect, useState } from "react";
import AOS from "aos";
import ValuesCard from "../../components/ValuesCard";
import SlideCard from "../../components/SlideCard";
import "aos/dist/aos.css";
import axios from "axios";
import BookingModal from "../../components/BookingModal";
import { toast } from "react-toastify";
import { stringConstant, toast_options } from "../../utils/constants";
import { fms_fleet } from "../../utils/webAPIs";
import { FMS_BACKEND_URL } from "../../utils/config";
import { useSelector } from "react-redux";
import OurServices from "../Components/OurServices";
import HowItWorks from "../Components/HowItWorks";
import TakeTour from "../Components/TakeTour";
import DownloadApp from "../Components/DownloadApp";
import HeroSection from "../Components/HeroSection";
import DevelopmentModel from "../Components/DevelopmentModal";
import ClassVehicles from "../Components/ClassVehicles";
import { Link } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";

const Home = () => {
  const [bookingModalVisible, setBookingModalVisible] = useState(false);
  const [developmentModalVisible, setDevelopmentModalVisible] = useState(true);

  const [carList, setCarList] = useState([]);
  const user = useSelector((state) => state.auth?.user);
  useEffect(() => {
    AOS.init();
    getFleetData();
  }, []);

  const getFleetData = () => {
    axios
      .get(`${FMS_BACKEND_URL}${fms_fleet}`)
      .then((res) => {
        // const { categories, vehicles } = res.data;
        // const result = categories.slice(0, 3).map((category) => ({
        //   ...category,
        //   vehicles: vehicles.filter(
        //     (vehicle) => vehicle.category === category.id
        //   ),
        // }));
        if (res.status === 200) {
          console.log(res);
          const { vehicleTypes, vehicles } = res.data.data;
          const result = [];
          vehicleTypes.map((category) => {
            // ...category,
            //       vehicles: vehicles.filter(
            //       (vehicle) => vehicle.type_id === category.id
            //   ),
            // if (result.length >= 3) return;
            const vehicle = vehicles.filter(
              (vehicle) => vehicle.type_id === category.id
            );
            if (vehicle.length > 0) {
              result.push({
                ...category,
                vehicles: vehicle,
              });
            }
          });
          console.log(result);
          setCarList(result);
        } else {
          toast.error(stringConstant.failed_register, toast_options);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <HeroSection />

      <div className="my-24 mt-[35rem] sm:mt-24 md:mt-8 relative">
        <ClassVehicles />
      </div>

      <div data-aos="fade-up">
        <OurServices />
      </div>
      <div>
        <HowItWorks />
      </div>
      <div className="w-full py-16" data-aos="fade-up">
        <div className="max-w-[1280px] w-[90%] m-auto flex items-center flex-col gap-20">
          <h1 className="text-3xl md:text-4xl lg:text-[50px] font-bold">
            Our Core Values
          </h1>
          <div className="flex flex-wrap justify-center w-full gap-6 md:flex-nowrap">
            <ValuesCard
              icon="safetyFirst.svg"
              title="Safety First"
              text="We make an extra effort to ensure that safety standard are at their highest peak  and adhered to at all times."
              data-aos="fade-up"
              data-aos-duration="150"
            />
            <ValuesCard
              icon="integrity.svg"
              title="Integrity"
              text="We are respectful and responsible for following through on our commitments to clients and other stakeholders."
              data-aos="fade-up"
              data-aos-duration="250"
            />
            <ValuesCard
              icon="accountability.svg"
              title="Accountability"
              text="We demand accountability in every aspect of our business from all our staff."
              data-aos="fade-up"
              data-aos-duration="350"
            />
          </div>
        </div>
      </div>
      <div className="w-full mt-8 sm:-mt-16 md:-mt-32">
        <div className="relative top-12 sm:top-24 md:top-48 -z-10 ">
          <div className="w-full  ">
            <img
              className="xl:w-full xl:relative "
              src="image/home/slide_2.jpg"
              alt=""
            />
          </div>
        </div>
        <img
          src="image/home/discover_yoks.svg"
          alt=""
          className="w-full z-10 relative"
          style={{
            top: "-30px",
          }}
        />
      </div>
      {/* <div className="-mt-12 sm:-mt-32 md:-mt-60" data-aos="fade-up">
        <div className="relative top-12">
          <TakeTour />
        </div>
      </div> */}
      <div className="w-[90%] max-w-4xl m-auto mt-20 mb-20" data-aos="fade-up">
        <Link
          to="/signin"
          className="bg-[#AE002B] text-white border rounded-md px-3 py-2 flex items-center gap-2 absolute top-[-60px] right-0 bot z-10"
        >
          Manage Your Booking
          <div className="w-6 h-6 flex items-center justify-center">
            <BsArrowUpRight />
          </div>
        </Link>
        <div
          className="flex flex-col col-span-1 gap-8"
          data-aos="fade-down"
          data-duration="200"
        >
          <div className="w-full">
            <SlideCard />
          </div>
        </div>
      </div>
      {/* </div> */}
      <BookingModal
        visible={bookingModalVisible}
        setVisible={setBookingModalVisible}
      />
      <DevelopmentModel
        visible={developmentModalVisible}
        setVisible={setDevelopmentModalVisible}
      />
      {/* <div className="py-16 relative -z-10 -top-48"> */}
      <DownloadApp />
      {/* </div>
      <div className="py-16 relative -z-10 -top-96"> */}
    </>
  );
};

export default Home;
