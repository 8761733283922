/* eslint-disable react-hooks/exhaustive-deps */
// React imports
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Third Party libraries
import { Modal, Tabs } from "antd";
import styled from "styled-components";
import Select from "react-select";

// Icon Imports
import { BsArrowUpRight, BsCalendar4Event } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";
import { LiaShareSolid } from "react-icons/lia";
import { TbClockShare } from "react-icons/tb";

import { useLocation } from "react-router-dom";
// Component and utility function imports
import ButtonDatePicker from "./ButtonDatePicker";
import ButtonSelect from "./ButtonSelect";
import ButtonTimePicker from "./ButtonTimePicker";
import RButton from "./RButton";
import { SET_BOOKING_STEP_1 } from "../redux/type";
import citydata from "../utils/citylist.json";
import { GOOGLE_API_KEY } from "../utils/config";
import Autocomplete from "react-google-autocomplete";
import { gettingBranches } from "../redux/actions/bookingAction";
import moment from "moment/moment";
const { TabPane } = Tabs;
const BModal = styled(Modal)`
  & > .ant-modal-content {
    padding: 0;
    button {
      color: white;
      top: 0px;
      right: 0px;
    }
    .ant-modal-header {
      background-color: #024273;
      padding: 15px;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
    }
    .ant-modal-body {
      padding-block: 20px;
    }
    .ant-modal-footer {
      display: none;
    }
  }
`;

const BTabs = styled(Tabs)`
  div.ant-tabs-nav,
  div.ant-tabs-content-holder {
    padding-inline: 20px;
  }
  & div.ant-tabs-tab {
    &.ant-tabs-tab-active > .ant-tabs-tab-btn {
      color: var(--text-color);
    }
    .ant-tabs-tab-btn {
      color: #faeaea;
    }
    .ant-tabs-tab-btn:focus {
      color: var(--text-color);
    }
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: #024273;
  }
`;

const BookingModal = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const [serviceType, setServiceType] = useState('whole_day');
  const book_state = useSelector((state) => state.book);
  const branches = useSelector((state) => state.globalData?.branches);
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
    setScreenWidth(window.innerWidth);
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [headerRef, visible]);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.clientHeight);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [selectedBranch, setSelectedBranch] = useState({
    label: "Main",
    value: 1,
  });
  const [serviceType, setServiceType] = useState(book_state?.serviceType);
  const [serviceHourly, setServiceHourly] = useState(book_state?.serviceHourly);
  const [startdate, setStartDate] = useState(book_state?.startdate);
  const [starttime, setStartTime] = useState(book_state?.starttime);
  const [enddate, setEndDate] = useState(book_state?.enddate);
  const [endtime, setEndTime] = useState(book_state?.endtime);
  const [pickupLocation, setPickupLocation] = useState(
    book_state?.pickupLocation
  );
  const [dropoffLocation, setDropoffLocation] = useState(
    book_state?.dropoffLocation
  );
  const [rentTime, setRentTime] = useState(1);

  const cityList = citydata;

  const onProceed = (type) => {
    const bookingData = {
      rtype: type,
      startdate,
      starttime,
      enddate,
      endtime,
      pickupLocation: pickupLocation,
      dropoffLocation: dropoffLocation,
      serviceType: serviceType,
      serviceHourly: serviceHourly,
      selectedBranch: selectedBranch,
    };
    const testing = dispatch({
      type: SET_BOOKING_STEP_1,
      payload: bookingData,
    });
    console.log(testing);
    console.log(bookingData);
    // return;
    if (location.pathname !== "/booking") {
      navigate("/booking");
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  useEffect(() => {
    // console.log(branches);
    if (branches.length === 0) {
      gettingBranches(dispatch);
    }
  }, [branches, visible]);
  const branchesWithValuesReturn = () => {
    const branchWithItem = branches.map((item) => {
      return {
        label: item.name,
        value: item.id,
        raw: item,
      };
    });
    if (selectedBranch === null && branchWithItem.length > 0) {
      setSelectedBranch(branchWithItem[0]);
    }
    return branchWithItem;
  };
  useEffect(() => {
    // console.log(pickupLocation)
  }, [pickupLocation]);
  const handleContinue = (type = 1) => {
    console.log("continue clicked");
    console.log(pickupLocation);
    console.log(dropoffLocation);
    console.log(selectedBranch);
    onProceed(type);
  };
  useEffect(() => {
    // check is start date is not null and endDate is lowever or same then add 1 day to end date
    if (startdate && enddate) {
      // setEndDate(enddate.add(1, 'days'));
      let sDate = moment(startdate?.format("YYYY-MM-DD"));
      let eDate = moment(enddate?.format("YYYY-MM-DD"));
      if (sDate.isSameOrAfter(eDate)) {
        setEndDate(moment(startdate?.format("YYYY-MM-DD")).add(1, "days"));
      }
    }
  }, [startdate, enddate]);
  useEffect(() => {
    // console.log(starttime, endtime)
    if (starttime && endtime) {
      //   console.log("start time is not null")
      //   let sTime = moment(starttime);
      //   let eTime = moment(endtime);
      //   console.log(sTime, eTime)
      if (starttime.isSameOrAfter(endtime)) {
        // console.log("start time is same or after end time")
        setEndTime(
          moment(starttime?.format("YYYY-MM-DD HH:mm:ss")).add(30, "minutes")
        );
      } else {
        // check is 30 minutes is less then end time
        if (endtime.diff(starttime, "minutes") < 29) {
          // console.log("end time is less then 30 minutes")
          setEndTime(
            moment(starttime?.format("YYYY-MM-DD HH:mm:ss")).add(30, "minutes")
          );
        }
      }
    }
  }, [starttime, endtime]);
  return (
    <BModal
      className=""
      title={
        <div className="flex items-center gap-5 w-full">
          <FaPlay className="text-white text-xl" />
          <p className="font-bold text-xl text-white">Book Now</p>
        </div>
      }
      width={screenWidth > 400 ? "80%" : "100%"}
      open={visible}
      onCancel={handleCancel}
    >
      {/*<Autocomplete*/}
      {/*    onPlaceSelected={(place) => console.log(place)}*/}
      {/*    className="w-full h-12 rounded-sm bg-[#F6F6F6] p-2 flex items-center justify-start gap-4"*/}
      {/*/>*/}
      <div
        ref={headerRef}
        className="mt-[-20px] shadow-lg p-5 absolute bg-white rounded-2xl z-50 w-[100%] sm:w-[110%] sm:ml-[-5%] flex justify-evenly items-center flex-col md:flex-row"
      >
        <p className="text-[32px]">How long do you want the car for?</p>
        <div className="flex items-center flex-col sm:flex-row">
          <RButton
            className="w-[100px] sm:w-[160px]"
            isradius={true}
            isfullwidth={true}
            onClick={() => {
              setRentTime(2);
            }}
            istransparent={rentTime === 1}
            style={{ marginRight: 10, width: 160 }}
          >
            <span className="flex justify-center items-center gap-2">
              Whole Day
            </span>
          </RButton>
          <div className="mt-2 sm:mt-0">
            <RButton
              className="w-[100px] sm:w-[160px]"
              isradius={true}
              istransparent={rentTime === 2}
              isfullwidth={true}
              style={{ width: 120 }}
              onClick={() => {
                setRentTime(1);
              }}
            >
              <span className="flex justify-center items-center gap-2">
                Hourly
              </span>
            </RButton>
          </div>
        </div>
      </div>
      {/*className={`sm:mt-0 mt-${headerHeight + 10 + 'px'}`}*/}
      <div
        style={{
          marginTop: headerHeight + 5,
        }}
        className={`grid grid-cols-1 md:grid-cols-2 ml-4 mr-4 sm:ml-[100px] sm:mr-[100px] justify-center items-center`}
      >
        <p className="text-[16px] col-span-1">
          Do you want to self-drive or chauffeur-driven?
        </p>
        <div className="col-span-1">
          <Select
            options={[
              { label: "Self-Drive", value: "self-drive" },
              { label: "Chauffeur Drive", value: "chauffeur-driven" },
            ]}
            value={serviceType}
            onChange={setServiceType}
          />
        </div>
      </div>
      {rentTime === 1 && (
        <div className="grid grid-cols-1 md:grid-cols-2 ml-4 mr-4 sm:ml-[100px] sm:mr-[100px] mt-4 justify-center items-center">
          <p className="text-[16px] col-span-1">
            For how many hours do you need the car?
          </p>
          <div className="col-span-1">
            <Select
              options={[
                { value: 1, label: "1 Hr" },
                { value: 2, label: "2 Hrs" },
                { value: 3, label: "3 Hrs" },
                { value: 4, label: "4 Hrs" },
                { value: 5, label: "5 Hrs" },
              ]}
              value={serviceHourly}
              onChange={setServiceHourly}
            />
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 ml-4 mr-4 sm:ml-[100px] sm:mr-[100px] mt-4 justify-center items-center">
        <div className="col-span-1 md:mr-4">
          <p className="text-[16px] col-span-1">
            Where will the car be picked up?
          </p>
          <ButtonSelect
            label={<LiaShareSolid />}
            options={null}
            value={pickupLocation}
            onOptionChange={(value) => {
              setPickupLocation(value);
            }}
            title={"From"}
          />
          <ButtonDatePicker
            onDateChange={(value) => setStartDate(value)}
            value={startdate}
            dvalue={startdate}
            icon={<BsCalendar4Event />}
            title={"Start Date"}
          />
          <ButtonTimePicker
            onTimeChange={(value) => {
              // setStartTime(value)
              setStartTime(moment(value?.format("YYYY-MM-DD HH:mm:ss")));
            }}
            value={starttime}
            icon={<TbClockShare />}
            defaultValue={moment(starttime).format("HH:mm:ss")}
            title={"Start Time"}
          />
        </div>
        <div className="col-span-1">
          <p className="text-[16px] col-span-1">Where will you drop off car?</p>
          <ButtonSelect
            label={<LiaShareSolid />}
            options={null}
            value={dropoffLocation}
            onOptionChange={(value) => {
              setDropoffLocation(value);
            }}
            title={"To"}
          />
          <ButtonDatePicker
            onDateChange={(value) => setEndDate(value)}
            value={enddate}
            startDate={startdate}
            icon={<BsCalendar4Event />}
            title={"End Date"}
          />
          <ButtonTimePicker
            onTimeChange={(value) => {
              setEndTime(moment(value?.format("YYYY-MM-DD HH:mm:ss")));
            }}
            value={endtime}
            icon={<TbClockShare />}
            defaultValue={moment(endtime).format("HH:mm:ss")}
            title={"End Time"}
          />
        </div>
      </div>
      <div className="flex justify-center items-center mt-8 mb-10">
        <div className="w-[400px]">
          <RButton
            isradius={true}
            isfullwidth={true}
           
            onClick={() => {
              handleContinue(rentTime);
            }}
          >
            <span className="flex w-full justify-center items-center gap-2 px-10">
              Continue <BsArrowUpRight className="font-bold" />
            </span>
          </RButton>
        </div>
      </div>
    </BModal>
  );
};

export default BookingModal;
