import React, { useEffect, useState } from "react";
import Carousel from "./Carousel";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { SET_LOADING } from "../../redux/type";
import { getFleetCarList } from "../../redux/actions/fleetAction";
import {
  setVehicleDetails,
  setVehicleID,
} from "../../redux/actions/bookingAction";

import RButton from "../../components/RButton";
import { FMS_MEDIA } from "../../utils/config";
import BookingModalNew from "../../components/BookingModalNew";

const vehicles = [
  {
    id: 1,
    image: "image/home/vehicle1.png",
    title: "Standard Sedan",
  },
  {
    id: 2,
    image: "image/home/vehicle2.png",
    title: "Compact SUV",
  },
  {
    id: 3,
    image: "image/home/vehicle3.png",
    title: "Executive SUV",
  },
  {
    id: 4,
    image: "image/home/vehicle1.png",
    title: "Standard Sedan",
  },
  {
    id: 5,
    image: "image/home/vehicle2.png",
    title: "Compact SUV",
  },
  {
    id: 6,
    image: "image/home/vehicle3.png",
    title: "Executive SUV",
  },
  {
    id: 7,
    image: "image/home/vehicle1.png",
    title: "Standard Sedan",
  },
];

const ClassVehicles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carList = useSelector((state) => state.fleet.carList);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [bookingModalVisible, setBookingModalVisible] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null); // State to track the selected car

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: true });
    getFleetCarList(dispatch);
  }, []);

  const handleSelectClick = (car) => {
    setSelectedCar(car);
    console.log("Selected Car Properties:", car);
    setVehicleID(dispatch, car.id);
    setVehicleDetails(dispatch, {
      ...car,
      vehicle_image: car.vehicles[0].vehicle_image,
      vehicles_type_name: car.displayname,
      model_name: car.vehicles[0].model_name,
      make_name: car.vehicles[0].make_name,
      vehicle_capacity: car.vehicles[0].vehicle_capacity,
      vehicle_price: car.vehicles[0].vehicle_price,
      vehicle_seats: car.vehicles[0].vehicle_seats,
      vehicle_transmission: car.vehicles[0].vehicle_transmission,
      vehicle_fuel: car.vehicles[0].vehicle_fuel,
      vehicle_doors: car.vehicles[0].vehicle_doors,
      vehicle_air_condition: car.vehicles[0].vehicle_air_condition,
      ...car.vehicles[0].meta_data.rates,
    });
    setBookingModalVisible(true);
  };

  return (
    <div className="w-[90%] max-w-6xl m-auto relative ">
      <Link
        to="/signin"
        className="text-[#AE002B] border border-black rounded-md px-3 py-2 flex items-center gap-2 absolute top-[-60px] right-0 bot z-10"
      >
        Manage Your Booking
        <div className="w-6 h-6 flex items-center justify-center">
          <BsArrowUpRight />
        </div>
      </Link>

      <img
        src="image/home/image 76.png"
        className="w-full mt-16"
        alt=""
        data-aos="fade-left"
        data-duration="600"
      />
      <h1 className="text-4xl text-[#181A1F] mt-12">Class of Vehicles</h1>
      <Carousel
        breakPoints={true}
        xbuttonMode={true}
        className="gap-2"
        data-aos="fade-up"
      >
        {carList.length > 0 ? (
          carList.map((item, index) => {
            if (item.vehicles && item.vehicles.length > 0) {
              const isSelected = selectedCar === item; // Check if the card is selected
              return (
                <div key={index} className="relative">
                  <button
                    onClick={() => handleSelectClick(item)}
                    className="relative overflow-hidden"
                  >
                    <div className="bg-white px-2 py-4 h-full">
                      <div
                        style={{ height: "400px" }}
                        className={`shadow-lg cursor-pointer flex flex-col relative overflow-hidden ${
                          isSelected ? "border-2 border-[#64b5f6] " : ""
                        }`}
                      >
                        <img
                          src={`${
                            item.vehicles.length > 0
                              ? FMS_MEDIA +
                                `/uploads/vehicles/${item.vehicles[0].vehicle_image}`
                              : "image/home/fleetcar1.png"
                          }`}
                          className="w-full object-contain flex-1 transition-transform duration-300 ease-in-out transform hover:scale-105"
                          alt={item.displayname}
                        />
                        <div className="w-full rounded-t-lg bg-[rgba(238,237,235,1)] h-18 px-3 flex items-center justify-between py-2">
                          <p className="text-[#181A1F] text-xl">
                            {item.displayname}
                          </p>
                          <div className="bg-[#AE002B] h-full px-5  rounded-full flex flex-col items-center justify-center">
                            <p className="text-white text-base">From</p>
                            <p className="text-white text-base currency_before ">
                              {" "}
                              {item.vehicles[0].meta_data.base_fare}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              );
            }
            return null;
          })
        ) : (
          <></>
        )}
      </Carousel>
      <div className="hidden absolute bottom-[30px] md:flex items-center justify-center w-full">
        <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center">
          {bookingModalVisible && (
            // <BookingModal
            //   visible={bookingModalVisible}
            //   setVisible={setBookingModalVisible}
            //   onClose={() => setBookingModalVisible(false)}
            // />
            <BookingModalNew
              visible={bookingModalVisible}
              onClose={() => setBookingModalVisible(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClassVehicles;
