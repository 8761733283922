//Global
export const SET_LOADING = "SET_LOADING";

// Auth
export const SIGN_UP = "SIGN_UP";
export const SIGN_IN = "SIGN_IN";

export const LOG_OUT = "LOG_OUT";

// Our Fleet
export const GET_CAR_LIST = "GET_CAR_LIST";

// Booking
export const SET_BOOKING_MODAL_VISIBLE = "SET_BOOKING_MODAL_VISIBLE";
export const SET_BOOKING_STEP_0 = "SET_BOOKING_STEP_0";
export const SET_BOOKING_STEP_1 = "SET_BOOKING_STEP_1";
export const SET_FLEET_LIST = "SET_FLEET_LIST";
export const SET_ADDONS = "SET_ADDONS";
export const SET_VEHICLE_ID = "SET_VEHICLE_ID";
export const SET_VEHICLE_DETAILS = "SET_VEHICLE_DETAILS";
export const SET_SELECTED_ADDONS = "SET_SELECTED_ADDONS";
export const SET_BOOKING_DETAILS = "SET_BOOKING_DETAILS";
export const CLEAR_BOOKING_DETAILS = "CLEAR_BOOKING_DETAILS";
export const GET_DISTANCE_COST = "GET_DISTANCE_COST";

//Global
export const SET_BRANCHES = "SET_BRANCHES";
export const VEHICLE_TYPE = "VEHICLE_TYPE";
export const VEHICLE_BY_TYPE = "VEHICLE_BY_TYPE";
export const FCM_SETTINGS = "FCM_SETTINGS";
export const SIGN_IN_TO_CONFIRM_BOOKING = "SIGN_IN_TO_CONFIRM_BOOKING";

// pagination for vehicle_by_type
export const VEHICLE_BY_TYPE_PAGINATION = "VEHICLE_BY_TYPE_PAGINATION";
