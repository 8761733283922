import React from "react";
import GooglePlayButton from "./../../components/GooglePlayButton";
import AppleButton from "./../../components/AppleButton";

export default function Footer() {
    return (
        <div className="flex justify-center">
            <div className="w-[80vw]">
                <footer className="text-white m">
                    <div
                        className="container mx-auto py-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div className="mb-8 md:mb-0">
                            <h4 className="text-xl font-bold mb-6">Quick Links</h4>
                            <ul className="space-y-4">
                                <li>
                                    <a className="hover:underline" href="/aboutus">
                                        About us
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="/services">
                                        Services
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="#">
                                        Promo
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="/thirdparty">
                                        Thirdparty
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="/contactus">
                                        Contact
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="mb-8 md:mb-0">
                            <h4 className="text-xl font-bold mb-6">Top cities</h4>
                            <ul className="space-y-4">
                                <li>
                                    <a className="hover:underline" href="#">
                                        Accra
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="#">
                                        Kumasi
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="#">
                                        Takoradi
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="#">
                                        Cape Coast
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="#">
                                        HO
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="mb-8 md:mb-0">
                            <h4 className="text-xl font-bold mb-6">Explore</h4>
                            <ul className="space-y-4">
                                <li>
                                    <a className="hover:underline" href="/service_detail?name=service_selfdrive">
                                        Self drive services
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="/service_detail?name=service_shuttle">
                                        Shuttle service
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="/service_detail?name=service_chauffaeur">
                                        Chauffeur service
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="/service_detail?name=service_meet">
                                        Meet & Greet service
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="/service_detail?name=service_tourpackages">
                                        Tours
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="/service_detail?name=service_meet">
                                        Airport transfer
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="mb-8 md:mb-0">
                            <h4 className="text-xl font-bold mb-6">Company Info</h4>
                            <ul className="space-y-4">
                                <li>
                                    <a className="hover:underline" href="/ourfleet">
                                        Contact Us
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="/ourfleet">
                                        Corporate Profile
                                    </a>
                                </li>
                                <li>
                                    <a className="hover:underline" href="/ourfleet">
                                        Site map
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="md:col-span-2 lg:col-span-1 lg:col-start-5">
                            <h4 className="text-xl font-bold mb-6">Quick Download Forms</h4>
                            <div className="flex flex-col space-y-4">
                                <button className="bg-black border border-white rounded-lg text-white p-2 px-4 flex items-center gap-4">
                                    <img src="image/download.svg" />
                                    <div className="px-2 border-l border-white/40 text-sm text-left">
                                       <a target="_blank" href="/assets/Third_Party_Vehicle_Amendment_Form_11.23.23.pdf">
                                       <p className="text-white">Third-party Vehicle</p>
                                       <p className="text-white">Delivery Form</p>
                                       </a>
                                    </div>
                                </button>
                                <button className="bg-black border border-white rounded-lg text-white p-2 px-4 flex items-center gap-4">
                                    <img src="image/download.svg" />
                                    <div className="px-2 border-l border-white/40 text-sm text-left">
                                        <a href="/assets/THIRD_PARTY_VEHICLE_OWNER_AGREEMENT_2023.pdf">
                                        <p className="text-white">Third-party Vehicle</p>
                                        <p className="text-white">Ownership Agreement</p>
                                        </a>
                                    </div>
                                </button>
                                <button className="bg-black border border-white rounded-lg text-white p-2 px-4 flex items-center gap-4">
                                    <img src="image/download.svg" />
                                    <div className="px-2 border-l border-white/40 text-sm text-left">
                                        <a href="/assets/NEW_INSPECTION_HANDOVER_CH_CK_LIST_AND_DRIVER_ACTIVITY_LOG.pdf">
                                        <p className="text-white">Third-party Vehicle</p>
                                        <p className="text-white">Checklist</p>
                                        </a>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}
