import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import RButton from "../components/RButton";
import { BsTelephone, BsList } from "react-icons/bs";
import { TbWorld, TbLogout, TbUserHexagon } from "react-icons/tb";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { LOG_OUT } from "../redux/type";
import Images from "../utils/ImageConstant";
import { getCookie } from "../utils/constants";

const HomeHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  // const isAuthenticated = getCookie("token");
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userdata = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const Logout = () => {
    dispatch({ type: LOG_OUT, payload: null });
    navigate("/");
  };
  useEffect(() => {
    console.log("Hi", isAuthenticated);
  }, []);

  const MenuButton = ({ to, title, moreClass }) => {
    return (
      <Link
        to={to}
        className={`w-22 h-18 flex items-center justify-center text-sm font-medium rounded-full cursor-pointer ${moreClass}`}
      >
        <p>{title}</p>
      </Link>
    );
  };

  return (
    <div
      className={`w-full top-0 z-50 ${location.pathname == "/" && "absolute"}`}
    >
      <div className="h-[79px] bg-[rgba(3,73,152,0.67)] w-full">
        <div className="max-w-[1280px] w-[90%] m-auto h-full flex items-center justify-between relative">
          <a
            href="/thirdparty"
            className="h-10 px-3 rounded-full bg-[rgba(247,148,30,0.8)] absolute top-1/2 -translate-y-1/2 left-1/2 items-center hidden lg:flex"
          >
            <span className="text-white">Peer 2 Peer Car rental</span>
          </a>
          <div className="flex gap-8">
            <img src="image/home/logo1.png" className="w-24 block lg:hidden" />
            <p className="text-white hidden lg:block">Hotline</p>
            <a href="tel:+ 233 540-614-545">
              <span className="text-sm items-center gap-1 font-semibold text-white hidden lg:flex cursor-pointer">
                <BsTelephone /> + 233 540-614-545
              </span>
            </a>
          </div>
          <div className="flex gap-6 items-center">
            <div className="hidden gap-2 items-center lg:flex">
              <a
                href="#"
                className="hover:bg-white/20 rounded-full flex items-center justify-center w-10 h-10"
              >
                <svg
                  width="9"
                  height="15"
                  viewBox="0 0 9 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.62891 8.52539H5.57812V14.6504H2.84375V8.52539H0.601562V6.00977H2.84375V4.06836C2.84375 1.88086 4.15625 0.650391 6.15234 0.650391C7.10938 0.650391 8.12109 0.841797 8.12109 0.841797V3.00195H7C5.90625 3.00195 5.57812 3.6582 5.57812 4.36914V6.00977H8.01172L7.62891 8.52539Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                href="#"
                className="hover:bg-white/20 rounded-full flex items-center justify-center w-10 h-10"
              >
                <svg
                  width="14"
                  height="12"
                  viewBox="0 0 14 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5508 2.80664C12.5508 2.94336 12.5508 3.05273 12.5508 3.18945C12.5508 6.99023 9.67969 11.3379 4.40234 11.3379C2.76172 11.3379 1.25781 10.873 0 10.0527C0.21875 10.0801 0.4375 10.1074 0.683594 10.1074C2.02344 10.1074 3.25391 9.64258 4.23828 8.87695C2.98047 8.84961 1.91406 8.0293 1.55859 6.88086C1.75 6.9082 1.91406 6.93555 2.10547 6.93555C2.35156 6.93555 2.625 6.88086 2.84375 6.82617C1.53125 6.55273 0.546875 5.4043 0.546875 4.00977V3.98242C0.929688 4.20117 1.39453 4.31055 1.85938 4.33789C1.06641 3.81836 0.574219 2.94336 0.574219 1.95898C0.574219 1.41211 0.710938 0.919922 0.957031 0.509766C2.37891 2.23242 4.51172 3.38086 6.89062 3.51758C6.83594 3.29883 6.80859 3.08008 6.80859 2.86133C6.80859 1.27539 8.09375 -0.00976562 9.67969 -0.00976562C10.5 -0.00976562 11.2383 0.318359 11.7852 0.892578C12.4141 0.755859 13.043 0.509766 13.5898 0.181641C13.3711 0.865234 12.9336 1.41211 12.332 1.76758C12.9062 1.71289 13.4805 1.54883 13.9727 1.33008C13.5898 1.9043 13.0977 2.39648 12.5508 2.80664Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                href="#"
                className="hover:bg-white/20 rounded-full flex items-center justify-center w-10 h-10"
              >
                <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.125 3.50586C8.84766 3.50586 10.2695 4.92773 10.2695 6.65039C10.2695 8.40039 8.84766 9.79492 7.125 9.79492C5.375 9.79492 3.98047 8.40039 3.98047 6.65039C3.98047 4.92773 5.375 3.50586 7.125 3.50586ZM7.125 8.70117C8.24609 8.70117 9.14844 7.79883 9.14844 6.65039C9.14844 5.5293 8.24609 4.62695 7.125 4.62695C5.97656 4.62695 5.07422 5.5293 5.07422 6.65039C5.07422 7.79883 6.00391 8.70117 7.125 8.70117ZM11.1172 3.39648C11.1172 3.80664 10.7891 4.13477 10.3789 4.13477C9.96875 4.13477 9.64062 3.80664 9.64062 3.39648C9.64062 2.98633 9.96875 2.6582 10.3789 2.6582C10.7891 2.6582 11.1172 2.98633 11.1172 3.39648ZM13.1953 4.13477C13.25 5.14648 13.25 8.18164 13.1953 9.19336C13.1406 10.1777 12.9219 11.0254 12.2109 11.7637C11.5 12.4746 10.625 12.6934 9.64062 12.748C8.62891 12.8027 5.59375 12.8027 4.58203 12.748C3.59766 12.6934 2.75 12.4746 2.01172 11.7637C1.30078 11.0254 1.08203 10.1777 1.02734 9.19336C0.972656 8.18164 0.972656 5.14648 1.02734 4.13477C1.08203 3.15039 1.30078 2.27539 2.01172 1.56445C2.75 0.853516 3.59766 0.634766 4.58203 0.580078C5.59375 0.525391 8.62891 0.525391 9.64062 0.580078C10.625 0.634766 11.5 0.853516 12.2109 1.56445C12.9219 2.27539 13.1406 3.15039 13.1953 4.13477ZM11.8828 10.2598C12.2109 9.4668 12.1289 7.55273 12.1289 6.65039C12.1289 5.77539 12.2109 3.86133 11.8828 3.04102C11.6641 2.52148 11.2539 2.08398 10.7344 1.89258C9.91406 1.56445 8 1.64648 7.125 1.64648C6.22266 1.64648 4.30859 1.56445 3.51562 1.89258C2.96875 2.11133 2.55859 2.52148 2.33984 3.04102C2.01172 3.86133 2.09375 5.77539 2.09375 6.65039C2.09375 7.55273 2.01172 9.4668 2.33984 10.2598C2.55859 10.8066 2.96875 11.2168 3.51562 11.4355C4.30859 11.7637 6.22266 11.6816 7.125 11.6816C8 11.6816 9.91406 11.7637 10.7344 11.4355C11.2539 11.2168 11.6914 10.8066 11.8828 10.2598Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                href="#"
                className="hover:bg-white/20 rounded-full flex items-center justify-center w-10 h-10"
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.73438 12.9004H0.191406V4.72461H2.73438V12.9004ZM1.44922 3.63086C0.65625 3.63086 0 2.94727 0 2.12695C0 1.33398 0.65625 0.677734 1.44922 0.677734C2.26953 0.677734 2.92578 1.33398 2.92578 2.12695C2.92578 2.94727 2.26953 3.63086 1.44922 3.63086ZM12.2227 12.9004H9.70703V8.93555C9.70703 7.97852 9.67969 6.77539 8.36719 6.77539C7.05469 6.77539 6.86328 7.78711 6.86328 8.85352V12.9004H4.32031V4.72461H6.75391V5.8457H6.78125C7.13672 5.2168 7.95703 4.5332 9.1875 4.5332C11.7578 4.5332 12.25 6.22852 12.25 8.41602V12.9004H12.2227Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
            {!isAuthenticated ? (
              <div className="flex gap-2 items-center">
                <Link
                  to="/signin"
                  className="bg-white/20 rounded-full px-4 py-2 transition-all hover:bg-[rgba(35,54,159,0.2)] text-white"
                >
                  Log In
                </Link>
                <Link
                  to="/signup"
                  className="bg-white/20 rounded-full px-4 py-2 transition-all hover:bg-[rgba(35,54,159,0.2)] text-white"
                >
                  Sign Up
                </Link>
                <span
                  className="block text-3xl font-bold text-white cursor-pointer lg:hidden"
                  onClick={toggleDrawer}
                >
                  <BsList />
                </span>
              </div>
            ) : (
              <div className="flex gap-2 items-center">
                <Link
                  to="/dashboard"
                  className="bg-white/20 rounded-full px-4 py-2 transition-all hover:bg-[rgba(35,54,159,0.2)] text-white"
                >
                  Dashboard
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20 bg-[rgba(255,255,255,0.81)] w-full hidden lg:block">
        <div className="max-w-[1280px] w-[90%] m-auto h-full flex items-center justify-between">
          <a href="/" className="block h-3/5">
            <img src="header_logo.png" className="h-full" />
          </a>
          <div className="flex items-center gap-4">
            <a
              href="/"
              className="py-2 px-5 rounded-full text-black text-xl hover:bg-[rgba(3,73,152,0.2)] transition-all"
            >
              Home
            </a>
            <a
              href="/services"
              className="py-2 px-5 rounded-full text-black text-xl hover:bg-[rgba(3,73,152,0.2)] transition-all"
            >
              Services
            </a>
            <a
              href="/ourfleet"
              className="py-2 px-5 rounded-full text-black text-xl hover:bg-[rgba(3,73,152,0.2)] transition-all"
            >
              Vehicles
            </a>
            <a
              href="/aboutus"
              className="py-2 px-5 rounded-full text-black text-xl hover:bg-[rgba(3,73,152,0.2)] transition-all"
            >
              About Us
            </a>
            <a
              href="/contactus"
              className="py-2 px-5 rounded-full text-black text-xl hover:bg-[rgba(3,73,152,0.2)] transition-all"
            >
              Contact
            </a>
          </div>
        </div>
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="bla"
      >
        <div className="w-full h-full pt-10 bg-[#FDEEEC] relative">
          <img src="image/logo.svg" className="w-48 m-auto" alt="" />
          <nav className="flex flex-col gap-6 text-base font-semibold text-[var(--text-color)] pl-8 pt-12">
            <a href="tel:+ 233 540-614-545">
              <span className=" flex items-center text-sm font-semibold text-[var(--text-color)] top-36 pl-8 cursor-pointer ">
                <BsTelephone /> + 233 540-614-545
              </span>
            </a>
            <Link onClick={toggleDrawer} to="/" className="cursor-pointer">
              Home
            </Link>
            <Link onClick={toggleDrawer} to="#" className="cursor-pointer">
              About Us
            </Link>
            <Link
              onClick={toggleDrawer}
              to="/aboutus"
              className="cursor-pointer pl-4"
            >
              About Us
            </Link>
            <Link
              onClick={toggleDrawer}
              to="/teams"
              className="cursor-pointer pl-4"
            >
              Our Team
            </Link>
            <Link
              onClick={toggleDrawer}
              to="/ourfleet"
              className="cursor-pointer"
            >
              Our Fleet
            </Link>
            <Link
              onClick={toggleDrawer}
              to="/services"
              className="cursor-pointer"
            >
              Services
            </Link>
            <Link onClick={toggleDrawer} to="/" className="cursor-pointer">
              Promo
            </Link>
            <Link
              onClick={toggleDrawer}
              to="/contactus"
              className="cursor-pointer"
            >
              Contact
            </Link>
            <Link
              onClick={toggleDrawer}
              to="/thirdparty"
              className="cursor-pointer"
            >
              Thirdparty
            </Link>
          </nav>
        </div>
      </Drawer>
    </div>
  );
};

export default HomeHeader;
