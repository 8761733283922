import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { getFleetCarList } from "../../redux/actions/fleetAction";
import { SET_LOADING } from "../../redux/type";
import BreadCrumb from "../../components/BreadCrumb";
import BottomBar from "../../components/BottomBar";
import RButton from "../../components/RButton";
import { BsArrowUpRight } from "react-icons/bs";
import { FMS_MEDIA } from "../../utils/config";
import {
  setVehicleDetails,
  setVehicleID,
} from "../../redux/actions/bookingAction";
import BookingModalNew from "../../components/BookingModalNew";

const OurFleet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carList = useSelector((state) => state.fleet.carList);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [bookingModalVisible, setBookingModalVisible] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null); // State to track the selected car

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: true });
    getFleetCarList(dispatch);
  }, []);

  const handleSelectClick = (car) => {
    setSelectedCar(car);
    console.log("Selected Car Properties:", car);
    setVehicleID(dispatch, car.id);
    setVehicleDetails(dispatch, {
      ...car,
      vehicle_image: car.vehicles[0].vehicle_image,
      vehicles_type_name: car.displayname,
      model_name: car.vehicles[0].model_name,
      make_name: car.vehicles[0].make_name,
      vehicle_capacity: car.vehicles[0].vehicle_capacity,
      vehicle_price: car.vehicles[0].vehicle_price,
      vehicle_seats: car.vehicles[0].vehicle_seats,
      vehicle_transmission: car.vehicles[0].vehicle_transmission,
      vehicle_fuel: car.vehicles[0].vehicle_fuel,
      vehicle_doors: car.vehicles[0].vehicle_doors,
      vehicle_air_condition: car.vehicles[0].vehicle_air_condition,
      ...car.vehicles[0].meta_data.rates,
    });
    setBookingModalVisible(true);
  };

  return (
    <>
      <div className="w-full">
        <BreadCrumb title="Our Fleet">
          <Link to="/">Home</Link> - <Link to="#">Our Fleet</Link>
        </BreadCrumb>
      </div>
      <div className="w-full">
        <div className="max-w-[1280px] w-[90%] m-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-8 gap-6 place-items-center">
            {carList.length > 0 ? (
              carList.map((item, index) => {
                if (item.vehicles && item.vehicles.length > 0) {
                  const isSelected = selectedCar === item; // Check if the card is selected
                  return (
                    <div
                      onClick={() => handleSelectClick(item)}
                      className="col-span-1 h-full"
                      key={index}
                    >
                      <div
                        className={`bg-white px-2 py-4 h-full border-2 ${
                          isSelected ? "border-[#64b5f6] " : ""
                        }`}
                      >
                        <div className="overflow-hidden shadow-lg  cursor-pointer h-full flex flex-col relative">
                          <div className="absolute top-0 right-1 z-10">
                            <RButton isradius={true}>
                              <span className="flex items-center gap-2 !text-sm">
                                Book Now
                                <BsArrowUpRight />
                              </span>
                            </RButton>
                          </div>
                          <img
                            src={`${
                              item.vehicles.length > 0
                                ? FMS_MEDIA +
                                  `/uploads/vehicles/${item.vehicles[0].vehicle_image}`
                                : "image/home/fleetcar1.png"
                            }`}
                            className="w-full object-contain flex-1 transition-transform duration-500 ease-in-out transform hover:scale-105"
                          />
                          <div className="w-full rounded-t-lg bg-[rgba(238,237,235,1)] h-18 px-3 flex items-center justify-between py-2">
                            <p className="text-[#181A1F] text-xl">
                              {item.displayname}
                            </p>
                            <div className="bg-[#AE002B] h-full  px-6 rounded-full flex flex-col items-center justify-center">
                              <p className="text-white text-base">From</p>
                              <p className="text-white text-base currency_before">
                                {" "}
                                {item.vehicles[0].meta_data.base_fare}
                              </p>
                            </div>
                          </div>
                          {/* <div className="mt-2">
                            <button
                              onClick={() => handleSelectClick(item)}
                              className="w-full py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition duration-300"
                            >
                              Select
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })
            ) : (
              <></>
            )}
            <div className="absolute bottom-[30px] md:flex items-center justify-center w-full">
              <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center">
                {bookingModalVisible && (
                  <BookingModalNew
                    visible={bookingModalVisible}
                    onClose={() => setBookingModalVisible(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomBar />
    </>
  );
};

export default OurFleet;
